import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { GENDER_IDS, BOOKING_STEPS, USER_LEVELS } from '~/config';
import routeNames from '~/utils/routeNames';
import useAuthStore from '~/store/auth';
import useShoppingCartStore from '~/store/shoppingCart';

// Waar zetten we dit neer? Aparte file?
function getAdminRoutes(userId: number, bookingId: number) {
  return {
    step: `/booking/user/${userId}/bookingstep`,
    booking: {
      info: `/booking/${bookingId}/user/${userId}/info`,
      start: `/booking/user/${userId}/start`,
      guestsGet: `/booking/${bookingId}/user/${userId}/travelparty/guests`,
      guestsSelect: `/booking/user/${userId}/travelparty/guests`,
      productsOverview: `/booking/user/${userId}/products/overview`,
      productsSelect: `/booking/user/${userId}/products/select`,
      summary: `/booking/${bookingId}/user/${userId}/summary`,
      finish: `/booking/summary/user/${userId}/book`,
    },
    pricing: {
      start: `/user/${userId}/start/pricing`,
      products: `/booking/${bookingId}/user/${userId}/products/pricing`,
      summary: `/booking/${bookingId}/user/${userId}/summary/pricing`,
    },
  };
}

function getUserRoutes(bookingId: number) {
  return {
    step: '/booking/bookingstep',
    booking: {
      info: `/booking/${bookingId}/info`,
      start: `/booking/start`,
      guestsGet: `/booking/${bookingId}/travelparty/guests`,
      guestsSelect: `/booking/travelparty/guests`,
      productsOverview: `/booking/products/overview`,
      productsSelect: `/booking/products/select`,
      summary: `/booking/${bookingId}/summary`,
      finish: `/booking/summary/book`,
    },
    pricing: {
      // start: `user/${userId}/start/pricing`,
      products: `/booking/${bookingId}/products/pricing`,
      summary: `/booking/${bookingId}/summary/pricing`,
    },
  };
}

// TODO: Store wordt te onoverzichtelijker, paar handlers kunnen gemaakt worden.
export default defineStore('bookingStore', () => {
  const { gtag } = useGtag();
  const router = useRouter();
  const authStore = useAuthStore();
  const bookingUser = ref(authStore.user);
  const shoppingCartStore = useShoppingCartStore();

  function setBookingUser(user: User) {
    bookingUser.value = user;
  }

  const step = ref(BOOKING_STEPS.INITIALIZE);
  const price = ref<BookingPricing>();
  const optionPrice = ref([]);
  const bookingCookie = useCookie('smook_activeBooking');
  const bookingId = ref(0);
  const bookingFlowEntered = ref(false);

  const isAdminBooking = computed(() => router.currentRoute.value.fullPath === routeNames.bookingForCustomer || authStore.user.isAdmin);
  const isBooking = computed(() => router.currentRoute.value.fullPath === routeNames.booking || isAdminBooking.value);

  // watch bookingUser, miss ook isAdminBooking. Als die veranderen dan moeten alle endpoints veranderen.
  // Miss isAdminBooking pas als de booking flow ingaat, anders ga je elke keer setten wanneer het niet nodig is.
  const bookingEndpoints = computed(() =>
    isAdminBooking.value ? getAdminRoutes(bookingUser.value.accountId, bookingId.value) : getUserRoutes(bookingId.value)
  );

  // Shopping cart resets bookingId, so wait until that reset is complete. It's super spaghetti.
  nextTick(() => {
    if (bookingCookie.value && !authStore.user.isAdmin) {
      bookingId.value = bookingCookie.value;
    }
  });

  const activeBookingItems = ref([]);
  const finishedBookingItems = ref([]);
  const bookingProducts = ref([]);
  const bookingSummary = ref([]);

  watch(step, (value) => {
    updatePrice(value);
  });

  watch(
    bookingId,
    (value) => {
      bookingCookie.value = toRaw(value);
      // isBooking wordt gecheckt, want soms wil je hem van buitenaf veranderen

      if (value && isBooking.value) {
        getBookingInfo(value).then((res) => {
          step.value = res.bookingStep;
        });
      } else {
        // reset
        step.value = BOOKING_STEPS.INITIALIZE;
        bookingUser.value = authStore.user;
        activeBookingItems.value = [];
        bookingFlowEntered.value = false;
      }
    }
    // { immediate: isBooking }
  );

  watch(
    bookingProducts,
    debounce(() => {
      if (bookingProducts.value?.length) {
        updateProducts().then(() => {
          getProductsPrice();
        });
      }
    }, 300),

    { deep: true }
  );

  function updateStep(value: number) {
    if (window) window.scrollTo(0, 0);
    step.value = value;
    if (value) {
      fetchData(
        bookingEndpoints.value.step,
        {
          method: 'post',
          body: {
            userId: bookingUser.value.accountId,
            bookingId: bookingId.value,
            bookingStep: value,
          },
        },
        false
      );
    }
  }

  function updateBookingId(value: number) {
    bookingId.value = value;
  }

  function updateBookingEntered(value: boolean) {
    bookingFlowEntered.value = value;
  }

  async function updatePrice(step: number) {
    switch (step) {
      case BOOKING_STEPS.ADDING_GUESTS:
        initGuests();
        // getTravelPartyPrice();
        break;
      case BOOKING_STEPS.ADDING_PRODUCTS:
        getProducts();
        getProductsPrice();
        break;
      case BOOKING_STEPS.SUMMARY:
        getSummary(bookingId.value);
        getSummaryPrice(bookingId.value);
        break;
    }
  }

  // step 0 - register
  async function registerUser(data: User, accommodations: ShoppingCartItem[], isOption: boolean) {
    try {
      const bookingUserCookie = useCookie('jfi_bookingUser');

      await fetchData('/booking/start/register', { method: 'post', body: data });
      const res = await fetchData('/booking/start/login', { method: 'post', body: data });

      const currentUser = { ...data, userLevel: USER_LEVELS.Booking };
      bookingUserCookie.value = JSON.stringify(currentUser);
      authStore.setToken(res?.token?.value);
      setBookingUser(data);
      setUser(currentUser);
      await startBookingFlow(accommodations, isOption);
    } catch (e) {
      console.log(e);
      setToast({
        type: 'negative',
        message: e.data?.detail || 'Registreren niet gelukt',
      });
    }
  }

  // step 1 - Wanneer ingelogd/ registered
  async function startBookingFlow(accommodations: ShoppingCartItem[], isOption = false) {
    if (isOption) {
      const optionData = {
        isOption,
        userId: bookingUser.value.accountId,
        accommodations,
      };
      return optionRequest(optionData);
    }

    if (bookingFlowEntered.value) {
      updateStep(BOOKING_STEPS.ADDING_GUESTS);
      return;
    }
    if (!bookingUser.value?.accountId && authStore.user) setBookingUser(authStore.user);

    const data = {
      isOption,
      userId: bookingUser.value.accountId,
      accommodations,
    };

    await initEmptyGuests();

    gtag('event', 'begin_checkout', {
      bookingId: bookingId.value,
      items: accommodations.map((item) => ({
        item_id: item.id,
        item_name: item.name,
      })),
    });
    const result = (await fetchData(bookingEndpoints.value.booking.start, {
      method: 'post',
      body: data,
    })) as { bookingId: number };

    if (result.bookingId) {
      bookingId.value = result.bookingId;

      bookingFlowEntered.value = true;
      updateStep(BOOKING_STEPS.ADDING_GUESTS);
      gtag('event', 'add_shipping_info', {
        bookingId: bookingId.value,
        items: accommodations.map((item) => ({
          item_id: item.id,
          item_name: item.name,
        })),
      });
    }

    return result;
  }

  async function optionRequest(data: any) {
    const result = (await fetchData(bookingEndpoints.value.booking.start, {
      method: 'post',
      body: data,
    })) as { bookingId: number };

    bookingId.value = result.bookingId;

    gtag('event', 'optie aangevraagd', {
      bookingId: bookingId.value,
      items: data.accommodations.map((item) => ({
        item_id: item.id,
        item_name: item.name,
      })),
    });

    await Promise.all([getBookingInfo(result.bookingId), getEstimatedPrice(data.accommodations)]).then((results) => {
      optionPrice.value = results[1];
    });
  }

  async function getBookingInfo(bookingId: number) {
    const activeItems = await fetchData(bookingEndpoints.value.booking.info);
    if (activeItems?.accommodations) {
      activeBookingItems.value = activeItems?.accommodations;
      return activeItems;
    }
    return [];
  }

  // Begin stap 2: init guests
  async function initGuests() {
    const res = await fetchData(bookingEndpoints.value.booking.guestsGet);
    const guestAccommodations = res.accommodations;
    activeBookingItems.value.forEach((acco, idx) => {
      const savedGuests = guestAccommodations[idx].units[0]?.guests;
      if (savedGuests.length) {
        acco.units[0].guests = savedGuests;
      } else {
        const user = bookingUser.value;

        acco.units.forEach((unit) => {
          unit.guests = [];
          for (let i = 0; i < unit.requestedNumberOfGuests; i++) {
            let guest = { firstName: '', lastName: '', gender: 'man', genderId: GENDER_IDS.man, dateOfBirth: null };
            if (i === 0 && user?.firstName) {
              guest.firstName = user.firstName;
              guest.lastName = user.lastName;
              guest.genderId = user.genderId;
              guest.dateOfBirth = user.dateOfBirth;
            }
            unit.guests.push(guest);
          }
        });
      }
    });

    return;
  }

  async function initEmptyGuests() {
    activeBookingItems.value?.forEach((acco, idx) => {
      const user = bookingUser.value;

      acco.units.forEach((unit) => {
        unit.guests = [];
        for (let i = 0; i < unit.requestedNumberOfGuests; i++) {
          let guest = { firstName: '', lastName: '', gender: 'man', dateOfBirth: null };
          if (i === 0 && user?.firstName) {
            guest = { ...user };
            // guest.firstName = user.firstName;
            // guest.lastName = user.lastName;
            // guest.gender = user.gender;
            // guest.genderId = user.genderId;
            // guest.dateOfBirth = user.dateOfBirth;
          }
          unit.guests.push(guest);
        }
      });
    });

    return;
  }

  // step 2
  async function updateBookingGuests() {
    const copyData = JSON.parse(JSON.stringify(activeBookingItems.value));
    copyData?.forEach((acco: BookingAccommodation) => {
      acco.units.forEach((unit) => {
        unit.guests.forEach((guest) => {
          guest.gender = GENDER_IDS[guest.gender] || 0;
          guest.dateOfBirth = guest.dateOfBirth ? toUtcAndStripTimeFormat(guest.dateOfBirth) : '';
        });
      });
    });
    await fetchData(bookingEndpoints.value.booking.guestsSelect, {
      method: 'post',
      body: {
        bookingId: bookingId.value,
        accommodations: copyData,
      },
    });
    updateStep(BOOKING_STEPS.ADDING_PRODUCTS);
  }

  async function getProducts() {
    const payload = {
      bookingId: bookingId.value,
      accommodations: activeBookingItems.value,
    };

    const res = await fetchData(bookingEndpoints.value.booking.productsOverview, {
      method: 'post',
      body: payload,
    });

    const EXCEPTION_PRODUCT = 'Ontbijt';
    const PER_PERSOON_CALC_TYPES = [1, 3, 6];
    function setProduct(product: Product, amountGuests: number) {
      // const CALCULATION_TYPES_MAPPING = {
      //   1: 'Per persoon per verblijf',
      //   2: 'Per stuk per nacht',
      //   3: 'Per persoon per nacht',
      //   4: 'Per stuk per nacht',
      //   6: 'Per persoon per week',
      //   7: 'Per stuk per week',
      // };

      if (product.minQuantity) {
        product.quantity = product.minQuantity;
      } else if (product.name == EXCEPTION_PRODUCT && product.calculationType == 3) {
        product.type = 'checkbox';
        product.checkboxVal = false;
      }

      if (product.isMandatory && PER_PERSOON_CALC_TYPES.includes(product.calculationType)) {
        product.quantity = amountGuests;
      }

      return product;
    }
    // Hacky way of fixing mandatory, should be done at BE but it's happening here.
    res.accommodations.forEach((acco: { products: Product[]; units: { products: Product[] }[] }, idx: number) => {
      const amountGuests = activeBookingItems.value[idx].units[0]?.requestedNumberOfGuests;
      acco.products.forEach((product: Product) => {
        product = setProduct(product, amountGuests);
      });

      acco.units[0].products.forEach((product: Product) => {
        product = setProduct(product, amountGuests);
      });
    });
    bookingProducts.value = res.accommodations;
  }

  // step 3
  async function updateProducts() {
    await fetchData(bookingEndpoints.value.booking.productsSelect, {
      method: 'post',
      body: {
        bookingId: bookingId.value,
        accommodations: bookingProducts.value,
      },
      watch: false,
    });
    return;
  }

  async function getSummary() {
    bookingSummary.value = null;
    const result = await fetchData(bookingEndpoints.value.booking.summary);
    bookingSummary.value = result;
  }

  // Kinda hacky, maar summary gebruikt op dit moment de store, en de methods zijn niet bruikbaar voor het ophalen boeking als admin
  async function getSummaryForUser(bookingId: number, userId: number) {
    bookingSummary.value = null;
    bookingSummary.value = await fetchData(`/booking/${bookingId}/user/${userId}/summary`);
    price.value = (await fetchData(`/booking/${bookingId}/user/${userId}/summary/pricing`)) as BookingPricing;
    const activeItems = await fetchData(`/booking/${bookingId}/user/${userId}/info`);
    activeBookingItems.value = activeItems.accommodations;

    return activeItems;
  }

  async function finishBooking(remark: string, termsAndConditionsAccepted: boolean) {
    // Admin moet voor eea reden eerder updaten? BE issue maar op deze manier met FE gefixt.
    if (authStore.user?.isAdmin) {
      updateStep(BOOKING_STEPS.FINISHED);
    }

    await fetchData(bookingEndpoints.value.booking.finish, {
      method: 'post',
      body: {
        bookingId: bookingId.value,
        remark,
        userId: bookingUser.value.accountId,
        termsAndConditionsAccepted,
      },
    });
    finishedBookingItems.value = activeBookingItems.value;

    await router.push(routeNames.boekingGedaan);

    gtag('event', 'purchase', {
      bookingId: bookingId.value,
      value: price.value?.total,
      currency: 'EUR',
      transaction_id: bookingId.value,
      items: activeBookingItems.value.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: price.value?.accommodations?.find((priceItem) => priceItem.id == item.id)?.total,
      })),
    });
    updateStep(BOOKING_STEPS.FINISHED);
    useShoppingCartStore().emptyShoppingCart();
    bookingId.value = 0;
  }

  async function addDiscount(discount: number) {
    await fetchData(`/booking/${bookingId.value}/user/${bookingUser.value.accountId}/discount`, {
      method: 'post',
      body: {
        userId: bookingUser.value.accountId,
        bookingId: bookingId.value,
        discount,
      },
    });
    getProductsPrice();
  }

  async function getTravelPartyPrice() {
    const payload = activeBookingItems.value;
    price.value = await getEstimatedPrice(payload);
  }

  async function getProductsPrice() {
    price.value = (await fetchData(bookingEndpoints.value.pricing.products)) as BookingPricing;
  }

  async function getSummaryPrice(bookingId: number) {
    price.value = (await fetchData(bookingEndpoints.value.pricing.summary)) as BookingPricing;
  }

  watch(shoppingCartStore.currentShoppingCart, () => {
    if (bookingId.value) {
      bookingId.value = 0;
    }
  });

  return {
    updateBookingGuests,
    startBookingFlow,
    bookingId,
    bookingProducts,
    updateProducts,
    activeBookingItems,
    getBookingInfo,
    finishBooking,
    finishedBookingItems,
    updateBookingId,
    bookingSummary,
    bookingFlowEntered,
    getSummary,
    getSummaryForUser,
    getSummaryPrice,
    setBookingUser,
    addDiscount,
    updateBookingEntered,
    isAdminBooking,
    getTravelPartyPrice,
    isBooking,

    step,
    price,
    optionPrice,

    updateStep,
    registerUser,
  };
});
