<template>
  <div>
    <div
      class="header headerDesktop"
      :style="{ top: navBarTop }"
    >
      <div class="topBar row justify-between">
        <div class="row q-gutter-md">
          <div class="row q-gutter-x-md items-center">
            <q-icon name="icon-phone" />

            <div>
              <span v-if="!isSmallScreen"> Beschikbaar voor vragen op </span>
              <u>
                <a
                  href="tel:+310108208521"
                  class="white"
                >
                  +31 (0)10 – 820 85 21</a
                >
              </u>
            </div>
          </div>
          <a
            href="https://www.sgr.nl/accounts/joy-for-italy-b-v/"
            class="row q-gutter-x-md items-center white"
            v-if="!isSmallScreen"
          >
            <q-icon name="icon-sgr" />
            <div>Boekingen beschermd met SGR</div>
          </a>
        </div>

        <div
          v-if="!isSmallScreen"
          class="rating row items-center"
        >
          <span> 4.2 / 5 </span>
          <q-rating
            class="q-ml-sm"
            max="5"
            size="1.2em"
            v-model="rating"
            icon-selected="star"
            icon-half="star_half"
            readonly
          />
        </div>
      </div>
      <q-toolbar
        class="toolbar"
        v-if="!isSmallScreen"
      >
        <div class="row items-center justify-between full-width">
          <div class="row items-center">
            <div class="q-pa-md">
              <router-link to="/">
                <img
                  class="logo"
                  loading="lazy"
                  src="~/assets/ecco-logo-accent.svg"
                  alt="ecco logo"
                />
              </router-link>
            </div>
            <div
              class="adminToolbar"
              v-if="isAdmin"
            >
              <span> Admin </span>

              <span
                class="version"
                v-if="version?.front || version?.back"
              >
                {{ version.front }}
              </span>
            </div>

            <div
              class="bestemmingen q-ml-sm"
              v-if="!destinationOnHold"
            >
              <span @click="bestemmingClick">
                Bestemmingen
                <q-icon
                  class="q-ml-xs"
                  name="icon-chevron-bottom"
                  size="sm"
                />
              </span>
              <div
                class="bestemmingContainer"
                v-show="showDestinationsMenu"
              >
                <LayoutDestinationsList @clicked-outside="showDestinationsMenu = false" />
              </div>
            </div>

            <router-link
              v-for="navItem in navItems"
              :key="navItem.label"
              class="navItem row justify-center align-center"
              :to="navItem.routeName"
            >
              <span>
                {{ navItem.label }}
              </span>
            </router-link>
          </div>

          <div class="row items-center">
            <router-link
              class="navItem row justify-center align-center"
              :to="routeNames.account"
            >
              <span> <q-icon name="icon-user" /> Mijn Ecco Italia </span>
            </router-link>
            <div
              class="navItem shoppingCart"
              :class="{ active: shoppingCartIsActive }"
            >
              <span>
                <q-icon
                  name="icon-shopping-cart"
                  class="navItemIcon"
                  id="shoppingCart"
                  size="20px"
                >
                  <q-badge
                    v-if="hasItems"
                    floating
                    rounded
                  />
                </q-icon>
              </span>
              <q-menu
                v-model="shoppingCartIsActive"
                class="shoppingCartMenu"
                max-height="85vh"
                anchor="bottom end"
                self="top end"
              >
                <div class="HeaderShoppingCart shoppingCartContent q-pa-lg">
                  <div v-if="hasItems">
                    <div class="heading4">Accommodaties in boeking</div>
                    <div class="textLowImportance">Boek je volledige reis in één keer!</div>

                    <BoekenShoppingCart
                      can-delete
                      has-button
                    />
                  </div>

                  <div
                    class="noItems"
                    v-else
                  >
                    <BoekenEmptyShoppingCartPlaceholder />
                  </div>
                </div>
              </q-menu>
            </div>
          </div>
        </div>
      </q-toolbar>

      <q-toolbar
        class="toolbar toolbarMobile q-pa-md"
        v-else
      >
        <q-toolbar-title class="row items-center">
          <router-link to="/">
            <img
              class="logo"
              loading="lazy"
              src="~/assets/ecco-logo-accent.svg"
              alt="ecco logo"
            />
          </router-link>
        </q-toolbar-title>

        <div class="row q-gutter-md">
          <router-link :to="routeNames.winkelwagen">
            <q-icon
              name="icon-shopping-cart"
              color="accent"
            >
              <q-badge
                v-if="hasItems"
                floating
                rounded
              />
            </q-icon>
          </router-link>

          <div @click="drawerOpen = !drawerOpen">
            <q-icon :name="drawerOpen ? 'close' : 'menu'" />
          </div>
        </div>
      </q-toolbar>

      <div
        v-show="drawerOpen"
        class="drawer"
      >
        <LayoutDestinationsList
          @open-state="showNavItemsMobile = !$event"
          v-if="!destinationOnHold"
        />
        <div v-if="showNavItemsMobile">
          <router-link
            v-for="navItem in navItems"
            :key="navItem.label"
            class="navItem row"
            :to="navItem.routeName"
          >
            <span>
              {{ navItem.label }}
            </span>
          </router-link>

          <router-link
            class="navItem row align-center"
            :to="routeNames.account"
          >
            <span> <q-icon name="icon-user" /> Mijn Ecco Italia </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useAuthStore from '~/store/auth';
import routeNames from '~/utils/routeNames';
import useShoppingCartStore from '~/store/shoppingCart';
import useStore from '~/store/store';
import { Screen } from 'quasar';
import { useI18n } from 'vue-i18n';
import { i18n } from '~/plugins/i18n';
import type { LayoutDestinationsList } from '#build/components';

const { t } = useI18n();
const store = useStore();
const authStore = useAuthStore();
const isAdmin = computed(() => authStore.user?.isAdmin);
const isLoggedIn = computed(() => authStore.user?.firstName);
const shoppingCartIsActive = ref(false);
const isSmallScreen = computed(() => Screen.width < 1000);
const version = computed(() => store.$state.version);
const drawerOpen = ref(false);
const router = useRouter();
const navBarTop = ref('0');
const rating = ref(4.5);
const showDestinationsMenu = ref(false);
const showNavItemsMobile = ref(true);
const destinationOnHold = true;

const shoppingCartStore = useShoppingCartStore();
const hasItems = computed(() => shoppingCartStore.currentShoppingCart.length);
const navItems = computed(() => {
  let items = [
    {
      label: t('general.desktopNavbar.landing'),
      routeName: routeNames.landing,
    },
    {
      label: t('general.desktopNavbar.inquiry'),
      routeName: routeNames.inquiry,
    },
    {
      label: t('general.desktopNavbar.upcomingTrips'),
      routeName: routeNames.upcomingTrips,
      needsLogin: true,
    },
    // {
    //   label: isLoggedIn.value ? t('general.desktopNavbar.accountLoggedIn') : t('general.desktopNavbar.accountNotLoggedIn'),
    //   routeName: routeNames.account,
    // },
    {
      label: t('general.desktopNavbar.contact'),
      routeName: routeNames.contact,
    },
  ];

  if (!isLoggedIn.value) items = items.filter((item) => !item.needsLogin);
  return items;
});

router.beforeEach(() => {
  if (drawerOpen.value) drawerOpen.value = false;
});

onMounted(() => {
  let prevScrollpos = window.scrollY;
  window.addEventListener('scroll', () => {
    var currentScrollPos = window.scrollY;
    if (prevScrollpos > currentScrollPos) {
      navBarTop.value = '0';
    } else if (currentScrollPos > 500 && isSmallScreen.value && !drawerOpen.value) {
      navBarTop.value = '-130px';
    }
    prevScrollpos = currentScrollPos;
  });
});

watch(isSmallScreen, (val) => {
  if (!val) drawerOpen.value = false;
});

function bestemmingClick() {
  if (showDestinationsMenu.value) return;
  setTimeout(() => {
    showDestinationsMenu.value = !showDestinationsMenu.value;
  }, 1);
}
</script>

<style lang="scss" scoped>
@import './styles/variables.sass';

.bestemmingContainer {
  position: absolute;
  top: 80px;
  margin-left: -20px;
}

.toolbar {
  .navItem:hover {
    span {
      border-bottom: 2px solid $accent;
    }
  }
}

.topBar {
  a {
    transition: 0.25s;

    span {
      transition: 0.25s;
    }

    &:hover {
      color: #abd8d8;

      span {
        color: #abd8d8;
      }
    }
  }

  .rating {
    .q-rating {
      color: white;
      transition: 0.25s;
    }

    /*
    &:hover {
      color: #abd8d8;
      .q-rating {
        color: #abd8d8;
      }
    }
        */
  }
}

.header {
  background: white;
  border-bottom: 1px solid $accent;
  color: $accent;

  span {
    color: $accent;
    font-weight: bold;
  }

  .navItem {
    padding: 0 15px;
  }
}

.white {
  color: white;
}

.topBar {
  background: $accent;
  color: white;
  padding: 12px 40px;
  text-align: center;
  color: white;
  span {
    color: white;
  }
}

.shoppingCartContent {
  width: 450px;

  .bookButton {
    width: 100%;
  }
}

.version {
  font-size: 12px;
}

.headerDesktop {
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  transition: 0.35s;

  .toolbar {
    height: 80px;
  }

  .shoppingCart {
    padding: 10px;
    border-radius: 8px 8px 0 0;
    transition: 0.3s;
    cursor: pointer;

    &.active {
      background: white;
      box-shadow: 0px 1px 1px 0px rgba(55, 55, 55, 0.2), 0px 4px 9px 1px rgba(0, 0, 0, 0.2);
    }
  }
}

.headerMobile {
  display: none;
}

.logo {
  width: 135px;
  height: 48px;
}

.navItemIcon {
  vertical-align: middle;
}

@media (max-width: 1000px) {
  .topBar {
    padding: 12px;
  }

  .logo {
    width: 110px;
    height: 40px;
  }

  .toolbarMobile {
    .q-icon {
      font-size: 32px;
    }

    .q-badge {
      padding: 0px 6px;
    }
  }

  .drawer {
    height: 100vh;

    .navItem {
      display: flex;
      min-height: 40px;
      padding: 8px 16px;
      font-size: 20px;
      align-self: stretch;
      span {
        font-weight: initial;
      }
    }
  }

  .pageContainer {
    // min-height: 90vh;
    margin-top: 0;
  }

  .headerMobile {
    display: initial;
    bottom: 0;
    width: 100vw;
    position: fixed;
    z-index: 9999;

    .navItemsContainer {
      display: flex;
      justify-content: space-between;
    }

    span {
      font-size: 13px;
    }

    .navItem {
      width: 100%;
      padding: 12px;

      .navItemIcon {
        padding-bottom: 4px;
      }
    }
  }
}
</style>
