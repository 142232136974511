import { defineStore } from 'pinia';
import { UNIT_FLOOR_LEVELS, DEFAULT_FLOOR_ITEM } from '~/config';

interface blockingConditions {
  id: number;
  conditions: BlockingCondition[];
}

export default defineStore('currentAccommodationStore', () => {
  // settings
  const router = useRouter();
  const activeRoute = computed(() => router.currentRoute.value.name);
  const isAdvice = computed(() => activeRoute.value == 'inkomende-reisaanvragen-inquiryId');
  const conditions = ref();
  const blockingConditions = reactive<blockingConditions[]>([]);

  // units logic handler
  const { selectedFloorFilter, lastDateSetUnitId, floorOptions, updatelastDateSetUnitId, updateSelectedFloorFilter } = unitsLogicHandler();

  // Hoort eigenlijk niet in deze store
  const showUnitsDialog = ref(false);

  // acco data
  // cookie for 3 months to keep track of recent viewed
  const recentCookie = useCookie<AccommodationDetail[]>('smook_recentViewed', { maxAge: 7889231 });
  const accommodationData = ref<AccommodationDetail>();

  const unitImages = reactive<{ id: number; images: string[] }[]>([]);
  const recentViewed = reactive<AccommodationDetail[]>([]);
  if (typeof recentCookie?.value === 'object' && recentCookie.value?.length > 0) {
    Object.assign(recentViewed, recentCookie.value);
  }
  const MAX_RECENT_SIZE = 5; // Cookies can only be 4kb. This is around 2.5kb to be safe.
  watch(accommodationData, (value: AccommodationDetail) => {
    const accoItem = {
      slug: value.slug,
      labels: [],
      thumbnailImageUrl: value.images?.length ? value.images[0]?.imageUrl : '',
      name: value.name,
      region: value.region,
      province: value.province,
      unitCountText: value.unitCountText,
    };

    const exists = recentViewed.findIndex((acco) => acco.slug == value.slug);
    if (exists > -1) {
      recentViewed.splice(exists, 1);
    }
    if (recentViewed.length >= MAX_RECENT_SIZE) {
      recentViewed.splice(MAX_RECENT_SIZE, 1);
    }
    recentViewed.unshift(accoItem);
    const recentCookieUpdated = useCookie('smook_recentViewed');
    recentCookieUpdated.value = JSON.stringify(toRaw(recentViewed));

    getConditions(value.id);
    if (value.units) {
      getUnitImages(value.units);
    }
    updateSelectedFloorFilter(DEFAULT_FLOOR_ITEM.value);
  });

  async function setAccommodation(acco: AccommodationDetail) {
    accommodationData.value = acco;
  }

  async function fetchAllAccoImages(slug: string) {
    const images: { images: ImageType[] } = (await fetchData(`/accommodation/${slug}/media`)) as { images: ImageType[] };
    if (images?.images?.length) accommodationData.value?.images.push(...images.images.splice(4));
  }

  async function getUnitImages(units: Unit[]) {
    // 4 is de max die de API meegeeft, dus bij 4 kijken we of er meer zijn
    const MAX_IMAGES = 4;
    unitImages.splice(0);

    // weet niet of await hier hanidg is. Miss testen met benchmark?
    units.forEach(async (unit) => {
      let images: ImageType[] = [];

      if (unit.images?.length == MAX_IMAGES) {
        images = await getAllImagesForUnit(unit.id);
      } else {
        images = unit.images;
      }

      if (images?.length) {
        unitImages.push({
          id: unit.id,
          images: images.map((item) => item.imageUrl),
        });
      }
    });
  }

  async function getAllImagesForUnit(unitId: number) {
    try {
      const imagesResponse = (await fetchData(`/unit/${unitId}/media`)) as { images: ImageType[] };
      return imagesResponse.images;
    } catch (error) {
      console.error('Error fetching images:', error);
    }
    return [];
  }

  async function getConditions(id: number) {
    const response = await fetchData(`/accommodation/${id}/conditions`);
    conditions.value = response?.conditions?.map((condition) => ({
      ...condition,
      fromTime: new Date(condition.fromDate).getTime(),
      toTime: new Date(condition.toDate).getTime(),
    }));
  }

  async function getSummary(slug: string, images: Object[]) {
    accommodationData.value = (await fetchData(`/accommodation/${slug}/summary`)) as AccommodationDetail;
    if (!accommodationData.value.images) {
      accommodationData.value.images = images;
    }
    return;
  }

  function setUnitsDialog(value: boolean) {
    showUnitsDialog.value = value;
  }

  function unitsLogicHandler() {
    const lastDateSetUnitId = ref(0);

    function updatelastDateSetUnitId(value: number) {
      lastDateSetUnitId.value = value;
    }
    const selectedFloorFilter = ref(DEFAULT_FLOOR_ITEM.value);

    function updateSelectedFloorFilter(value: number) {
      selectedFloorFilter.value = value;
    }

    const floorOptions = computed(() => {
      const items = [...new Set(accommodationData.value?.units?.map((item) => item.floor))].sort().map((item) => ({
        label: UNIT_FLOOR_LEVELS[item],
        value: item,
      }));
      return [DEFAULT_FLOOR_ITEM, ...items];
    });

    return {
      selectedFloorFilter,
      lastDateSetUnitId,
      floorOptions,
      updatelastDateSetUnitId,
      updateSelectedFloorFilter,
    };
  }

  return {
    accommodationData,
    setAccommodation,
    getSummary,
    isAdvice,
    conditions,
    blockingConditions,
    recentViewed,
    unitImages,
    showUnitsDialog,
    fetchAllAccoImages,
    setUnitsDialog,
    selectedFloorFilter,
    lastDateSetUnitId,
    floorOptions,
    updatelastDateSetUnitId,
    updateSelectedFloorFilter,
  };
});
