<template>
  <div
    class="DestinationsList"
    ref="destinationsList"
    :style="{ width }"
  >
    <div
      v-if="isMobile && hasSelectedItem"
      class="menuItem"
      @click="backOneStep"
    >
      <q-icon name="icon-chevron-left" />
      Terug
    </div>
    <div v-if="showRowOne">
      <div class="category">Bestemmingen</div>
      <LayoutDestinationItem
        v-for="subItem in item.subItems"
        :key="subItem.label"
        :text="subItem.label"
        :hasSubItems="!!subItem.subItems"
        :active="subItem.label == selectedItem?.label"
        @click="selectedItem = subItem"
      />
    </div>

    <div v-if="showRowTwo">
      <div class="category">{{ selectedItem.label }}</div>
      <LayoutDestinationItem
        v-for="subItem2 in selectedItem.subItems"
        :key="subItem2.label"
        :text="subItem2.label"
        :hasSubItems="!!subItem2.subItems"
        :active="subItem2.label == selectedItem2?.label"
        @click="selectedItem2 = subItem2"
      />
    </div>

    <div v-if="showRowThree">
      <div class="category">{{ selectedItem2.label }}</div>
      <LayoutDestinationItem
        v-for="subItem3 in selectedItem2.subItems"
        :key="subItem3.label"
        :text="subItem3.label"
        :hasSubItems="false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const selectedItem = ref();
const selectedItem2 = ref();
const hasSelectedItem = computed(() => !!selectedItem.value || !!selectedItem2.value);
const isMobile = getIsMobile();
const showRowOne = computed(() => (isMobile.value ? !selectedItem.value : true));
const showRowTwo = computed(() => (isMobile.value ? selectedItem.value && !selectedItem2.value : selectedItem.value));
const showRowThree = computed(() => (isMobile.value ? selectedItem2.value : selectedItem2.value));
const destinationsList = ref();
const emit = defineEmits(['open-state', 'clicked-outside']);

watch(
  () => hasSelectedItem.value,
  (val) => {
    emit('open-state', val);
  }
);

watch(
  selectedItem,
  (val) => {
    console.log(val);
    selectedItem2.value = null;
  },
  { deep: true }
);

const width = 'auto';
const item = {
  label: 'Bestemmingen',
  subItems: [
    {
      label: 'Regios',
      subItems: [
        {
          label: 'Toscane',
          routeName: routeNames.landing,
          subItems: [
            {
              label: 'Florence',
              routeName: routeNames.landing,
            },
            {
              label: 'Siena',
              routeName: routeNames.landing,
            },
          ],
        },
        {
          label: 'Umbrie',
          routeName: routeNames.landing,
        },
        {
          label: 'Le Marche',
          routeName: routeNames.landing,
        },
        {
          label: 'Sicilie',
          routeName: routeNames.landing,
        },
      ],
      // routeName: routeNames.landing,
    },
    {
      label: 'Meren',
      subitems: [
        {
          label: 'Toscane',
          routeName: routeNames.landing,
        },
        {
          label: 'Umbrie',
          routeName: routeNames.landing,
        },
        {
          label: 'Le Marche',
          routeName: routeNames.landing,
        },
        {
          label: 'Sicilie',
          routeName: routeNames.landing,
        },
      ],
      // routeName: routeNames.landing,
    },
    {
      label: 'Streken',
      subitems: [
        {
          label: 'Toscane',
          routeName: routeNames.landing,
        },
        {
          label: 'Umbrie',
          routeName: routeNames.landing,
        },
        {
          label: 'Le Marche',
          routeName: routeNames.landing,
        },
        {
          label: 'Sicilie',
          routeName: routeNames.landing,
        },
      ],
      // routeName: routeNames.landing,
    },
  ],
};

function backOneStep() {
  if (selectedItem2.value) {
    selectedItem2.value = null;
  } else if (selectedItem.value) {
    selectedItem.value = null;
  }
}

onMounted(() => {
  if (!isMobile.value) {
    window.addEventListener('click', (event) => {
      if (!destinationsList.value) {
      } else if (!destinationsList.value?.contains(event.target)) {
        emit('clicked-outside');
      }
    });
  }
});

onUnmounted(() => {
  window.removeEventListener('click', () => {
    console.log('removed');
  });
});
</script>

<style lang="scss" scoped>
.DestinationsList {
  // width: 560px;
  background: white;
  border-radius: 24px;
  display: flex;
  gap: 20px;

  padding: 24px;
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(55, 55, 55, 0.2);
}

.category {
  font-weight: bolder;
  padding: 12px 16px;
}

@media (max-width: 1000px) {
  .DestinationsList {
    padding: 0;
    box-shadow: none;
    flex-direction: column;
    font-size: 20px;
    display: block;
    width: 100%;
  }

  .menuItem {
    padding: 12px;
  }
}
</style>
