<template>
  <div class="HeaderTextAndImageContentBlock">
    <NuxtImg
      class="full-width backgroundImage"
      :src="content.headerAfbeelding.id.toString()"
      provider="baseProvider"
      width="100%"
      height="auto"
      fit="cover"
      loading="lazy"
      sizes="xl:1500px lg:1200px md:800px sm:800px xs:400px"
    />

    <div class="row justify-center">
      <div
        class="content row q-pa-xl"
        :class="reverseClass"
      >
        <div class="col-xs-12 col-sm-6 tekst">
          <div class="heading2">
            {{ content.titel }}
          </div>

          <div
            v-html="content.tekst"
            class="q-mt-lg light raw"
          />
          <ContentblokkenButton
            :name="content.knop.name"
            :link="content.knop.link"
            :target="content.knop.target"
          />
        </div>
        <div class="col-xs-12 col-sm-6 imageContainer">
          <NuxtImg
            class="Image full-width"
            :src="content.afbeelding.id.toString()"
            provider="baseProvider"
            width="100%"
            height="auto"
            loading="lazy"
            sizes="lg:600px md:400px sm:350px xs:400px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<HeaderTekstEnAfbeeldingContentBlock>,
    required: true,
  },
});

const reverseClass = props.content.uitlijningTekst === 'false' ? 'reverse' : 'non-reverse';
</script>

<style lang="scss" scoped>
@import '~/styles/variables.sass';

.HeaderTextAndImageContentBlock {
  position: relative;
}

.content {
  background: $background;
  top: 50%;
  max-width: 1350px;
  border-radius: 16px;
  margin-top: -250px;
}

.backgroundImage {
  max-height: 500px;
  object-fit: cover;
}

.Image {
  border-radius: 8px;
  float: right;
}

.reverse {
  .tekst {
    padding-left: 24px;
  }
}

.non-reverse {
  .tekst {
    padding-right: 24px;
  }
}

.imageContainer {
  border-radius: 8px;
  background-repeat: no-repeat;
}

@media (max-width: $screen-xs) {
  .content {
    position: relative;
    margin-top: 0;
  }

  .Image {
    margin-top: 10px;
  }

  .heading2 {
    font-size: 24px;
  }

  .reverse {
    .tekst {
      padding-left: 0;
    }
  }
}
</style>
