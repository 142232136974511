type CombinedParams = SearchValueAvailability & GuestData;

function hideDefault(params: CombinedParams) {
  return (
    params.orderBy == 'NameAsc' &&
    params.adults == 2 &&
    params.children == 0 &&
    params.babies == 0 &&
    params.bedrooms == 0 &&
    params.allowSofaBeds == false
  );
}

export default function configureSearchParams(newFilters: CombinedParams) {
  const payload = newFilters;
  const deleteKeys: string[] = [];
  if (hideDefault(payload)) {
    deleteKeys.push('orderBy', 'adults', 'children', 'babies', 'bedrooms', 'allowSofaBeds');
  }
  if (payload.page === 1) deleteKeys.push('page');
  const searchParams = new URLSearchParams(JSON.parse(JSON.stringify(payload)));
  searchParams.forEach((param, key) => {
    if (!param) deleteKeys.push(key);
  });
  deleteKeys.forEach((key) => searchParams.delete(key));

  return decodeURIComponent(searchParams.toString());
}
