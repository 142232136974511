<template>
  <div class="ZoekEnBoekContentBlock">
    <div
      class="q-mb-sm"
      :title="content.titel"
      :heading-type="content.titelOpmaak"
      v-if="content.titel"
    />

    <client-only>
      <AanbodView
        :initial-filter-data="filterData"
        :initial-search-data="searchFilterData"
        :is-advice="false"
      />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { HOLIDAY_TYPES, ACCOMODATION_TYPES, QUALITY_LABELS } from '~/config';

const props = defineProps({
  content: {
    type: Object as PropType<AccommodatiesContentblock>,
    required: true,
  },
});

const filterData: Record<string, string[]> = {
  // page: 1,
  // name: '',
  // [HOLIDAY_TYPES]: [],
  // [LABELS]: [],
  // [QUALITY_LABELS]: [],
  // [ACCOMODATION_TYPES]: [],
  // [ACCOMODATION_SUB_FILTERS]: [],
  // [FEATURES]: [],
  // [DISCOUNTS]: [],
  // orderBy: 'NameAsc',
};

const searchFilterData: { location: Record<string, number[]> } = {
  location: {
    regionIds: [],
    environmentIds: [],
  },
};

const mappingFilterData: Record<string, string> = {
  accommodatieTypes: ACCOMODATION_TYPES,
  kwaliteitslabels: QUALITY_LABELS,
  vakantieTypes: HOLIDAY_TYPES,
};

onMounted(() => {
  Object.entries(props.content).forEach((item: [string, string]) => {
    if (mappingFilterData[item[0]] && item[1]) {
      filterData[mappingFilterData[item[0]]] = item[1].split(', ');
    } else if (item[0] == 'regio' && item[1]) {
      searchFilterData.location.regionIds = item[1].split(',').map((item) => parseInt(item, 10));
    }
  });
});
</script>

<style lang="scss" scoped>
.QuoteContentBlock {
  color: $text-medium;
}
</style>
