<template>
  <div class="UspsContentBlock row justify-center q-gutter-x-md">
    <ContentblokkenHeadingTypeComponent
      v-if="content.titel"
      class="q-mb-sm col-xs-12"
      :title="content.titel"
    />
    <router-link
      v-for="usp in usps"
      class="usp col-md-3 col-xs-5 row items-center q-col-gutter-x-md"
      :to="usp.content.link"
    >
      <q-icon name="icon-star-full" />
      <span>
        {{ usp.content.titel }}
      </span>
    </router-link>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  content: {
    type: Object as PropType<UspsContentBlock>,
    required: true,
  },
});

const usps = ref<ElocusPage[]>([]);

if (props.content.usps) {
  const uspCsv = props.content.usps.split(', ');
  const uspList = (await fetchData('/api/pages/2266')) as ElocusPage[];
  if (uspList.length === 0) throw 'No USP found';

  if (uspList.length) {
    for (let usp of uspCsv) {
      const find = uspList?.find((item) => item._id == usp);
      if (find) usps.value.push(find);
    }
  }
}
</script>

<style lang="scss" scoped>
.usp {
  flex-wrap: nowrap;
  color: $accent;
}

@media (max-width: $screen-md) {
  .UspsContentBlock {
    justify-content: start;
  }
}

@media (max-width: $screen-xs) {
  .usp {
    font-size: 14px;
    padding-bottom: 12px;
  }
}
</style>
