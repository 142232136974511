<template>
  <div class="ShoppingCartPricing">
    <div class="costs">
      <div>
        <h3
          class="heading4"
          v-t="'booking.stepper.paymentInfo'"
        />

        <div v-if="price">
          <div
            v-for="(accommodation, accoIdx) in price.accommodations"
            class="accommodationPrice"
          >
            <div class="row">
              <div class="col-8">{{ getUnitName(accoIdx) }} overnachting</div>
              <div class="col-4 text-right">{{ euroFormatter(accommodation.unitTotal) }}</div>
            </div>

            <div v-if="accommodation.productTotal">
              <div class="row justify-between">
                <div class="col-8">{{ getUnitName(accoIdx) }} extra services</div>
                <div class="col-4 text-right">{{ euroFormatter(accommodation.productTotal) }}</div>
              </div>
              <hr />
            </div>
          </div>

          <div v-for="fee in price.fixedFees">
            <div class="row justify-between">
              <div class="col-8">{{ fee.name }}</div>
              <div class="col-4 text-right">{{ euroFormatter(fee.price) }}</div>
            </div>
          </div>

          <div v-if="price.discountTotal">
            <hr class="discount" />

            <div
              v-for="discount in price.discounts"
              class="row justify-between"
            >
              <span v-text="discount.name" />
              <b v-text="euroFormatter(discount.price)" />
            </div>

            <div class="row justify-between">
              <span> Korting totaal </span>
              <b v-text="euroFormatter(price.discountTotal)" />
            </div>
          </div>

          <hr class="final" />

          <div class="row justify-between">
            <b> Totaal </b>
            <b v-text="euroFormatter(price.total)" />
          </div>
          <div
            class="textLowImportance text-center q-my-lg"
            v-if="bookingStep < BOOKING_STEPS.ADDING_PRODUCTS && !isAdminBooking"
          >
            <i> Deze kosten kunnen nog afwijken </i>
          </div>
        </div>

        <div v-else>Geen prijs beschikbaar</div>
      </div>
      <q-btn
        v-if="showDiscount"
        color="primary-dark"
        outline
        class="q-mt-lg"
        @click="showDiscountModal = true"
      >
        {{ discountStateText }}
      </q-btn>
    </div>

    <DialogComponent
      v-model="showDiscountModal"
      :title="discountStateText"
    >
      <TextInput
        label-text="Korting"
        v-model="discount"
        type="number"
      />

      <q-btn
        v-if="!hasDiscountAdded"
        color="primary"
        class="q-mt-lg"
        @click="addDiscount"
        :disable="discount < 0"
      >
        Toevoegen
      </q-btn>

      <div
        v-else
        class="q-mt-lg"
      >
        <q-btn
          color="primary"
          @click="addDiscount"
          :disable="discount < 0"
        >
          Aanpassen
        </q-btn>

        <q-btn
          color="red"
          outline
          class="q-ml-lg"
          @click="removeDiscount"
        >
          Verwijderen
        </q-btn>
      </div>
    </DialogComponent>
  </div>
</template>

<script setup lang="ts">
import useBookingStore from '~/store/booking';
import useShoppingCartStore from '~/store/shoppingCart';
import { BOOKING_STEPS } from '~/config';

const props = defineProps({
  isOption: {
    type: Boolean,
    default: false,
  },

  useShoppingCart: {
    type: Boolean,
    default: true,
  },

  showDiscount: {
    type: Boolean,
    default: false,
  },
});

const shoppingCartStore = useShoppingCartStore();
const bookingStore = useBookingStore();
const isAdminBooking = computed(() => bookingStore.isAdminBooking);
const price = computed(() => (props.isOption ? bookingStore.optionPrice : bookingPrice.value));
const bookingPrice = computed(() =>
  bookingStore.step > BOOKING_STEPS.ADDING_GUESTS || isAdminBooking.value ? bookingStore.price : shoppingCartStore.shoppingCartPrice
);
const bookingStep = computed(() => bookingStore.step);
const showDiscountModal = ref(false);
const discount = ref(0);
const hasDiscountAdded = ref(false);
const discountStateText = computed(() => (hasDiscountAdded.value ? 'Korting aanpassen' : 'Korting toevoegen'));

function getUnitName(accoIdx: number) {
  //   Bij stap 2 + boeking info meegeven
  if (props.useShoppingCart) {
    return shoppingCartStore.currentShoppingCart[accoIdx]?.unit?.name;
  }

  if (!!bookingStore.activeBookingItems) {
    return bookingStore.activeBookingItems[accoIdx]?.units[0]?.name;
  }
  return '';
}

function addDiscount() {
  bookingStore.addDiscount(discount.value);
  showDiscountModal.value = false;
  hasDiscountAdded.value = discount.value > 0 ? true : false;
}

function removeDiscount() {
  discount.value = 0;
  addDiscount();
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.sass';

.accommodationPrice hr {
  border: 1px solid $backgrounds-150;
  margin: 8px 0;
}

hr {
  &.final {
    border: 1px solid $backgrounds-150;
    margin: 24px 0;
  }

  &.discount {
    border: 1px solid $text-grey;
    margin: 12px 0;
  }
}
</style>
